<template>
  <div v-if="user" class="container-fluid text-center mt-5">
    <div class="row">
      <div class="col-sm-12 mb-3">
        <div class="card">
          <div class="card-body">
            DRIVER <b>{{ user.name }}</b>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <router-link class="no-text-decoration" :to="`/loads/${uuid}`">
          <div class="callout callout-default">
            <h4>ASSIGNED LOADS</h4>
            go to assigned loads
          </div>
        </router-link>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <router-link class="no-text-decoration" :to="`/history/${uuid}`">
          <div class="callout callout-primary">
            <h4>COMPLETED LOADS</h4>
            go to completed uploads
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { simpleGet, simplePost } from "../api/constants";
import { useRoute, useRouter } from "vue-router";
//import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  setup() {
    //da6c7d40-e0b2-11ee-9fc8-0f9361e21fcd
    const route = useRoute();
    const router = useRouter();
    const uuid = route.params.id;
    const user = ref();
    if (!uuid) {
      router.push("/");
    }
    onMounted(async () => {
      const us = await usCheck();
      user.value = us;
      console.log(us);
    });
    const getXLoads = async () => {
      const list_xloads = await simpleGet("xloads/simple");
      return list_xloads.data;
    };
    const usCheck = async () => {
      const obj = await simplePost("macro/uscheck", { uid: uuid });
      if (obj.data == "notfound") {
        router.push("/");
      }
      return obj.data;
    };
    const updateXLoads = async (item) => {
      const obj = await simplePost("xloads/update/" + item.uuid, item);
      return obj.data;
    };

    return {
      uuid,
      user,
      getXLoads,
      usCheck,
      updateXLoads,
    };
  },
};
</script>
<style lang="scss" scoped>
.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}
@each $name, $color in (default, #777), (primary, #428bca), (success, #5cb85c),
  (danger, #d9534f), (warning, #f0ad4e), (info, #5bc0de), (bdc, #29527a)
{
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}
.no-text-decoration {
  text-decoration: none;
}
</style>

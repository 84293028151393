/* eslint-disable prettier/prettier */
import axios from "axios";
export const API_URL = "https://spartan.app.3dboosterstudio.com/api/";

export const simpleGet = async (path) => {
  try {
    const data = await axios
      .get(API_URL + path)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const simplePost = async (path, form) => {
  try {
    const formData = new FormData();
    for (const i in form) {
      formData.append(i, form[i]);
    }
    const url = API_URL + path;
    const data = await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const formatNumberUs = (value) => {
  return new Intl.NumberFormat("en-us").format(value);
};

export const dataTextTrips = (trips) => {
  let out = "";
  let shippers = 0;
  trips.forEach((item) => {
    const arr = item.location.split(", ");
    const arr2 = arr[1].split(" ");
    out += out ? "-" + arr2[0] : arr2[0];
    if (item.type_trip == "Shipper") {
      shippers++;
    }
  });
  const consg = trips.length - shippers;
  const x = `${out}  (${shippers} pickup   ${consg} delivery)`;
  return x;
};

export const loadStatus = [
  { value: "Pending", color: "warning" },
  { value: "In progress", color: "success" },
  { value: "Completed", color: "primary" },
  { value: "Late", color: "danger" },
  { value: "Cancelled", color: "danger" },
];
export default API_URL;
